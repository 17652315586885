html,
body,
#root,
.App {
  height: 100%;
  min-height: 100%;
}

html {
  font-family: innogy-Medium, Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
}

body {
  overflow-x: hidden;
}

*,
*:before,
*:after {
  outline: 0;
}
